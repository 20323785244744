<template>
  <div class="p-3 rounded-3 bg-gray-200 container my-4">
    <div class="info-box bg-white p-4 rounded-2 shadow-sm">
      <p class="text-center fw-bold">{{ title }}</p>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  emits: [],
  methods: {},
}
</script>

<style lang="scss" scoped>
.info-box {
}
</style>
